import React, { useEffect, useState } from 'react';
import { Container, Typography, CircularProgress, List, ListItem, ListItemText, TextField, Button } from '@mui/material';

function App() {
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [formData, setFormData] = useState({ name: '', email: '' });


  useEffect(() => {
    fetch('http://localhost:8080/api/message')
      .then(response => response.json())
      .then(data => {
        setMessage(data.message);
        setLoading(false);
      })
      .catch(err => {
        console.error(err);
        setLoading(false);
      });
    console.log(message)
    console.log(loading)
    fetch('http://localhost:8080/api/users')
      .then(response => response.json())
      .then(data => setUsers(data))
      .catch(err => console.error(err));
  }, []);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch('http://localhost:8080/api/users', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to add user');
        }
        return response.json();
      })
      .then(data => {
        // Update the users list with the newly added user
        setUsers(prevUsers => [...prevUsers, data]);
        // Reset the form
        setFormData({ name: '', email: '' });
      })
      .catch(err => console.error(err));
  };

  return (
    <Container maxWidth="md" style={{ textAlign: 'center', marginTop: '50px' }}>
      <Typography variant="h2" gutterBottom>
        Welcome to React & Flask Integration!
      </Typography>

      {loading ? (
        <CircularProgress />
      ) : (
        <Typography variant="h5" gutterBottom>
          {message}
        </Typography>
      )}

<Typography variant="h4" style={{ marginTop: '40px' }}>
        Add a New User
      </Typography>
      <form onSubmit={handleSubmit} style={{ marginTop: '20px' }}>
        <TextField
          label="Name"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          type="email"
          fullWidth
          margin="normal"
          required
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          style={{ marginTop: '20px' }}
        >
          Submit
        </Button>
      </form>

      <Typography variant="h4" style={{ marginTop: '40px' }}>
        User List
      </Typography>
      <List>
        {users.map((user) => (
          <ListItem key={user.id}>
            <ListItemText primary={`${user.name} (${user.email})`} />
          </ListItem>
        ))}
      </List>
    </Container>
  );
}

export default App;